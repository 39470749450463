import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';

const Layout = () => {
    const location = useLocation();
    const getTopBarTitle = (pathname: string) => {
        switch (pathname) {
            case '/':
                return 'Dashboard';
            case '/users':
                return 'Users';
            case '/deposit':
                return 'Deposits';
            case '/house':
                return 'House Edge';
            case '/withdraw':
                    return 'Withdraw';
            case '/wallet':
                    return 'User Wallet';
            case '/history':
                    return 'History';
            default:
                return 'App';
        }
    };

   return (
        <div className='flex h-screen bg-gray-100 overflow-hidden'>
            <Sidebar />
            <div className='flex-1 overflow-auto relative'>
            <div className="bg-[#161b2b] text-white text-2xl font-semibold p-4 sticky top-0 z-10 text-center">
    {getTopBarTitle(location.pathname)}
</div>

                <div className='p-10 pt-16'>
                    <Outlet /> {/* This will render the component based on the route */}
                </div>
            </div>
        </div>
    );
    // (
    //     <div className='flex h-screen bg-gray-100 overflow-hidden'>
    //         <Sidebar />
    //         <div className='flex-1 overflow-auto relative'>
    //             <div className="bg-red-500 text-white text-2xl font-semibold p-4 sticky top-0 z-10">
    //                 {getTopBarTitle(location.pathname)} {/* Dynamic top bar title */}
    //             </div>
    //             <div className='p-10 pt-16'>
    //                 <Outlet />
    //             </div>
    //         </div>
    //     </div>
    // );
};

export default Layout;
