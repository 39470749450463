import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from '../assets/logo.png';
const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    navigate("/login");
  };

  return (
    <div className="w-64 min-h-screen shadow-md bg-[#161b2b] px-1 text-white">
      <div className="p-4 flex items-center justify-center">
        <img src={logo} alt="Logo" className="h-16 w-full" />
      </div>
      <nav>
        <NavLink
          to="/"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-yellow-500 text-black" : "")
          }
        >
          Dashboard
        </NavLink>

        <NavLink
          to="/users"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-yellow-500 text-black" : "")
          }
        >
          Users 
        </NavLink>
        <NavLink
          to="/deposit"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-yellow-500 text-black" : "")
          }
        >
          Deposit
        </NavLink>

         <NavLink
          to="/withdraw"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-yellow-500 text-black" : "")
          }
        >
         Withdraw
        </NavLink>

         {/* <NavLink
          to="/referal"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-yellow-500 text-black" : "")
          }
        >
          Games
        </NavLink> */}

         <NavLink
          to="/house"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-yellow-500 text-black" : "text")
          }
        >
          House Edge
        </NavLink>

         <NavLink
          to="/wallet"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-yellow-500 text-black" : "")
          }
        >
          User Wallet
        </NavLink>
        <NavLink
          to="/history"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-yellow-500 text-black" : "")
          }
        >
          History
        </NavLink>
{/*       
        <NavLink
          to="/income"
          className={({ isActive }) =>
            "block py-2.5 px-4 rounded transition duration-200" +
            (isActive ? " bg-yellow-500 text-black" : "")
          }
        >
         Settings
        </NavLink> */}
       
        {/* Logout Button */}
        <button
          onClick={handleLogout}
          className="block py-2.5 text-blue px-4 rounded transition duration-200"
        >
          Logout
        </button>
      </nav>
    </div>
  );
};

export default Sidebar;
