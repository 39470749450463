import React, { useState, useEffect } from 'react';
import Tooltip from './Tooltip';

type User = {
  id: string;
  name: string;
  email: string;
  number: string;
  total_deposit_usdt: number;
  total_withdrawal_usdt: number;
};

interface DepositTransaction {
  uid: number | string;
  date: string;
  txtid: string;
  amount: number;
  coin: string;
}

interface LiveBet {
  game: string;
  uid: number;
  amount: number;
  coin: string;
  name: string;
}

interface WithdrawalTransaction {
  uid: number | string;
  date: string;
  wallet: string;
  amount: number;
  coin: string;
  name: string | null;
}

const AdminDashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [totalDeposits, setTotalDeposits] = useState(0);
  const [totalWithdrawals, setTotalWithdrawals] = useState(0);
  const [users, setUsers] = useState<User[]>([]);
  const [todayDeposits, setTodayDeposits] = useState(0);
  const [todayWithdrawals, setTodayWithdrawals] = useState(0);
  const [liveBets, setLiveBets] = useState<LiveBet[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [transactions, setTransactions] = useState<Array<DepositTransaction | WithdrawalTransaction>>([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userResponse = await fetch('https://api.ibitplay.com/users');
        const userData = await userResponse.json();
        setUsers(userData);
        setUserCount(userData.length);

        const totalDepositsResponse = await fetch('https://api.ibitplay.com/total-deposits');
        const totalDepositsData = await totalDepositsResponse.json();
        setTotalDeposits(totalDepositsData.total_deposits_usdt);

        const totalWithdrawalsResponse = await fetch('https://api.ibitplay.com/total-withdrawals');
        const totalWithdrawalsData = await totalWithdrawalsResponse.json();
        setTotalWithdrawals(totalWithdrawalsData.total_withdrawals_usdt);

      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    };

    const fetchTodayDepositsAndWithdrawals = async () => {
      try {
        const today = new Date().toISOString().split('T')[0];

        const depositResponse = await fetch('https://api.ibitplay.com/today-deposits');
        const depositData = await depositResponse.json();

        const totalDepositAmountToday = depositData.reduce((total: any, deposit: { date: string; amount: any; }) => {
          const depositDate = deposit.date.split('T')[0];
          return depositDate === today ? total + deposit.amount : total;
        }, 0);
        setTodayDeposits(totalDepositAmountToday);

        const withdrawResponse = await fetch('https://api.ibitplay.com/today-withdrawals');
        const withdrawData = await withdrawResponse.json();

        const totalWithdrawalAmountToday = withdrawData.reduce((total: any, withdraw: { date: string; amount: any; }) => {
          const withdrawDate = withdraw.date.split('T')[0];
          return withdrawDate === today ? total + withdraw.amount : total;
        }, 0);
        setTodayWithdrawals(totalWithdrawalAmountToday);

      } catch (error) {
        console.error('Failed to fetch deposit or withdrawal data:', error);
      }
    };

    fetchTodayDepositsAndWithdrawals();
    fetchUserData();
  }, []);

  const fetchTodayDeposits = async () => {
    const response = await fetch('https://api.ibitplay.com/today-deposits');
    const data: DepositTransaction[] = await response.json();
    const today = new Date().toISOString().split('T')[0];
    const todayDeposits = data.filter(deposit => new Date(deposit.date).toISOString().split('T')[0] === today);
    setTransactions(todayDeposits);
  };

  const fetchTodayWithdrawals = async () => {
    const response = await fetch('https://api.ibitplay.com/today-withdrawals');
    const data: WithdrawalTransaction[] = await response.json();
    const today = new Date().toISOString().split('T')[0];
    const todayWithdrawals = data.filter(withdrawal => new Date(withdrawal.date).toISOString().split('T')[0] === today);
    setTransactions(todayWithdrawals);
  };

  const handleShowTransactions = async (type: 'deposit' | 'withdrawal') => {
    setShowModal(true);
    if (type === 'deposit') {
      await fetchTodayDeposits();
    } else {
      await fetchTodayWithdrawals();
    }
  };

  const fetchLiveBets = async () => {
    try {
      const response = await fetch('https://api.ibitplay.com/live-bets');
      const data: LiveBet[] = await response.json();
      setLiveBets(data);
    } catch (error) {
      console.error('Failed to fetch live bets:', error);
    }
  };

  useEffect(() => {
    fetchLiveBets();
    const interval = setInterval(fetchLiveBets, 10000);
    return () => clearInterval(interval);
  }, []);


  return (
    <div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 text-white mb-10'>
        <div className='bg-sky-500 shadow rounded-lg p-4'>
          <span className='text-white'>Total Users</span>
          <div className='text-xl font-bold'>{userCount}</div>
        </div>
        <div className='bg-green-500 shadow rounded-lg p-4'>
          <span className='text-white'>Total Deposits</span>
          <div className='text-xl font-bold'>${totalDeposits.toFixed(2)}</div>
        </div>
        <div className='bg-red-500 shadow rounded-lg p-4'>
          <span className='text-white'>Total Withdrawals</span>
          <div className='text-xl font-bold'>${totalWithdrawals.toFixed(2)}</div>
        </div>
        <div className='bg-yellow-500 shadow rounded-lg p-4'>
          <span className='text-white'>Live Users</span>
          <div className='text-xl font-bold'>0</div>
        </div>
        <div className='bg-green-800 shadow rounded-lg p-4 flex justify-center items-center'>
          <Tooltip message="Tap to show today's deposit" onClick={() => handleShowTransactions('deposit')}>
            <span className='text-white'>Today's Deposit</span>
          </Tooltip>
        </div>
        <div className='bg-pink-700 shadow rounded-lg p-4 flex justify-center items-center'>
          <Tooltip message="Tap to show today's withdrawal" onClick={() => handleShowTransactions('withdrawal')}>
            <span className='text-white'>Today's Withdrawal</span>
          </Tooltip>
        </div>
        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg max-w-sm w-full p-5 relative">
              <h2 className="text-lg font-bold text-black m-0">Today's Transactions</h2>
              <button onClick={() => setShowModal(false)} className="absolute top-2 right-2 text-2xl text-gray-600 hover:text-gray-800">
                &times;
              </button>
              {transactions.length > 0 ? (
                <div className="mt-4">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="border-b-2 border-gray-200 p-2 text-left text-black">UID</th>
                        <th className="border-b-2 border-gray-200 p-2 text-left text-black">Date</th>
                        <th className="border-b-2 border-gray-200 p-2 text-left text-black">Amount</th>
                        <th className="border-b-2 border-gray-200 p-2 text-left text-black">Coin</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((transaction, index) => (
                        <tr key={index} className="even:bg-gray-50">
                          <td className="p-2 text-black">{transaction.uid}</td>
                          <td className="p-2 text-black">{new Date(transaction.date).toLocaleDateString()}</td>
                          <td className="p-2 text-black">{transaction.amount}</td>
                          <td className="p-2 text-black">{transaction.coin.toUpperCase()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="mt-4 text-gray-600">No transactions available.</div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className='flex justify-center items-start h-screen'>
        <div className='w-[800px] h-[450px] overflow-auto relative rounded-lg'>
          <table className="text-sm text-left text-gray-500 w-full">
            <thead className="text-m text-white uppercase bg-[#161b2b] sticky top-0 z-10">
              <tr>
                <th scope="col" className="py-3 px-6">Live Bets</th>
                <th scope="col" className="py-3 px-6"></th>
                <th scope="col" className="py-3 px-6"></th>
                <th scope="col" className="py-3 px-6"></th>
                <th scope="col" className="py-3 px-6"></th>
                <th scope="col" className="py-3 px-6"></th>
              </tr>
            </thead>
            <thead className="text-xs text-gray-700 uppercase bg-gray-50  top-[3rem] z-10">
              <tr>
                <th scope="col" className="py-3 px-6">S.No</th>
                <th scope="col" className="py-3 px-6">Name</th>
                <th scope="col" className="py-3 px-6">Uid</th>
                <th scope="col" className="py-3 px-6">Game</th>
                <th scope="col" className="py-3 px-6">Bet</th>
                <th scope="col" className="py-3 px-6">Coin</th>
              </tr>
            </thead>
            <tbody>
              {liveBets.reverse().map((bet, index) => (
                <tr key={index} className="bg-white border-b hover:bg-gray-50">
                  <td className="py-4 px-6">{index + 1}</td>
                  <td className="py-4 px-6">{bet.name}</td>
                  <td className="py-4 px-6">{bet.uid}</td>
                  <td className="py-4 px-6">{bet.game}</td>
                  <td className="py-4 px-6">${parseFloat(bet.amount.toString()).toFixed(8)}</td>
                  <td className="py-4 px-6">{bet.coin.toUpperCase()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
